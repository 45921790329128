import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import {
  errors,
  userResponses,
} from "../../../../../store/survey/survey.slice";

//styles
import "../../../../../assets/css/survey/input.css";

//utils
import { getStorage, setStorage } from "../../../../../utils/Storage";

const InputCPFComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const userSurvey = useSelector(userResponses);
  const [localInput, setLocalInput] = useState("");

  const CPFMask = (value) => {
    let clearValue = value.replaceAll(".", "").replaceAll("-", "");

    let rx = new RegExp(/^[0-9]*$/gm);

    if (rx.exec(clearValue)) {
      let valueExtract = clearValue.split("");
      let valueTracted = [];

      for (let i = 0; i <= 10; i++) {
        if (i === 3 || i === 6) {
          if (valueExtract[i]) {
            valueTracted.push("." + valueExtract[i]);
          }
        } else if (i === 9) {
          if (valueExtract[i]) {
            valueTracted.push("-" + valueExtract[i]);
          }
        } else {
          if (valueExtract[i]) {
            valueTracted.push(valueExtract[i]);
          }
        }
      }

      const valueFormatted = valueTracted.join("");
      return valueFormatted;
    }

    return localInput;
  };

  const changeInput = (val) => {
    let surveyData = getStorage(surveyid, true);
    const valueFormatted = CPFMask(val);

    if (valueFormatted !== "") {
      surveyData.user.responses[data.id] = valueFormatted;
    } else {
      delete surveyData.user.responses[data.id];
    }

    setStorage(surveyid, surveyData, true);
    setLocalInput(valueFormatted);
  };

  useEffect(() => {
    if (userSurvey.responses[data.id]) {
      setLocalInput(userSurvey.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="input-component" className={`cpf ${data?.cssClass || ""}`}>
      <div className="input-question">
        <Interweave content={data.title[language]} />
      </div>
      <div className="input-content">
        <input
          type="text"
          maxLength={data?.properties?.maxLength || 15}
          value={localInput}
          placeholder="000.000.000-00"
          onChange={({ target: { value } }) => changeInput(value)}
          onKeyUp={(e) => {
            const input = e.target;
            const length = input.value.length;
            input.focus();
            input.setSelectionRange(length, length);
          }}
        />
      </div>
      <div className="input-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default InputCPFComponent;
